/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

// Image
import networkRack from '../assets/img/How-To-Organize-Cables-In-Rack-Cabinet.webp'

import '../showcase.css'

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section id="showcase"
        className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-white">
              IoT Service Pros
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-white">
              Your IoT solutions partner. We offer end-to-end expertise in the Internet of Things. 
              From consultation to implementation, we optimize connectivity, security, and efficiency for your connected devices. 
              Empower your business with smart solutions.
              </p>
              <div className="mt-12">
                <Link
                  to="/get-started"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none 
                  focus:outline-none mr-1 mb-1 bg-blue-500
                  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Get Started
                </Link>
                <Link
                  to="/contact-us"
                  className="github-star ml-1 text-white font-bold px-6 py-4 rounded 
                  outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 
                  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/pattern_react.png").default}
          alt=""
        /> */}
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col bg-blue-500 min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                <img
                  alt=""
                  src={networkRack}
                  width={800}
                  height={429}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className=" fill-current"
                      style={{color: '#3b82f6'}}
                    ></polygon>
                  </svg>
                  <h3 className="text-xl font-bold text-white">
                    Your Business Your Network
                  </h3>
                  <p className="text-md font-light mt-2 text-white">
                    Your network is the backbone of your business, don't let bad posture limit your profits. 
                    Having the right installer can prevent internet outages and dropped phone calls.
                  </p>
                  <Link
                  to="/"
                    className="font-bold text-white hover:text-blueGray-800 ease-linear transition-all duration-150"
                  >
                    Get A Quote Today{" "}
                    <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                  </Link>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-12/12 lg:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-server"></i>
                      </div>
                      <h4 className="text-xl mb-1 font-semibold">
                      Firewalls
                      </h4>
                      <p className="mb-4 text-blueGray-800">
                      Shield your digital world! A firewall safeguards data from cyber threats, 
                      ensuring your privacy and security. Don't compromise, stay protected.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-phone"></i>
                      </div>
                      <h4 className="text-xl mb-1 font-semibold">
                      VOIP Phones
                      </h4>
                      <p className="mb-4 text-blueGray-800">
                      Upgrade communication! VOIP phones offer HD calls, cost savings, 
                      and remote flexibility. Elevate productivity and collaboration seamlessly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i class="fas fa-network-wired"></i>
                      </div>
                      <h4 className="text-xl mb-1 font-semibold">Switches</h4>
                      <p className="mb-4 text-blueGray-800">
                      Connectivity matters. Switches enable seamless data sharing, faster speeds, 
                      and efficient networks. Upgrade to smoother operations now.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i className="fas fa-tv"></i>
                      </div>
                      <h4 className="text-xl mb-1 font-semibold">
                      Workstations
                      </h4>
                      <p className="mb-4 text-blueGray-800">
                      Reliability counts. Ethernet cables deliver stable, high-speed connections 
                      for workstations, ensuring smooth workflow and consistent productivity. If it's not mobile, wire it!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>     
      </section>

      
      <Footer />
    </>
  );
}
